import { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"
import { styled } from "styled-components"

import { MdDelete } from "react-icons/md";
import { MdModeEdit } from "react-icons/md";
import { FaPlus } from "react-icons/fa";

import { createProvider, deleteProvider, getProviders } from "../services/ProvidersService"
import { useAppContext } from "../contextApi/context"
import {
    PROVIDER_CODE_DUPLICATED,
    PROVIDER_ID_DUPLICATED,
    PROVIDER_CONTACT_PHONE_DUPLICATED,
    PROVIDER_EMAIL_DUPLICATED,
    PROVIDER_CONTACT_EMAIL_DUPLICATED,
    PROVIDER_ASSOCIATED_WITH_STOCK,
    PROVIDER_PHONE_NUMBER_DUPLICATED
} from "../constants/apiMessages"
import DeleteConfirmationModal from "../components/shared/DeleteConfirmationModal"
import ConfirmationModal from "../components/shared/ConfirmationModal"
import { AddButton, TableActionButton } from "../styles/StyledComponents"
import { checkPermission } from "../utils/permissions";
import TableContainer from "../components/shared/TableContainer";

const Providers = () => {
    // ----- Context/Hooks
    const { loggedUser } = useAppContext();

    // ----- State
    const [idToDelete, setIdToDelete] = useState(null)
    const [showForm, setShowForm] = useState(false)
    const [showProviderDeleted, setShowProviderDeleted] = useState(false)
    const [showErrorDuplicatedData, setShowErrorDuplicatedData] = useState(false)
    const [errorDuplicatedData, setErrorDuplicatedData] = useState('')
    const [showErrorAssignedProvider, setShowErrorAssignedProvider] = useState(false)
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [updateTable, setUpdateTable] = useState(false);

    // ----- Hooks
    const { register, reset, handleSubmit, formState: { errors } } = useForm();
    const navigate = useNavigate();

    // ----- Actions
    const handleCloseDeleteConfirmationModal = () => {
        setIdToDelete(null);
        setShowDeleteConfirmation(false);
    }

    const openDeleteConfirmation = (roleId) => {
        setIdToDelete(roleId);
        setShowDeleteConfirmation(true)
    }

    const handleCreate = async (data) => {
        clearMessages();

        const response = await createProvider(loggedUser.token, data);

        if (response.status === 201) {
            navigate(`/proveedores/${response.data.id}`);
        } else {
            const { data } = response;
            let errorMessageDuplicated = "El proveedor contiene datos que ya se encuentran registrados:";

            if (data.includes(PROVIDER_CODE_DUPLICATED)) {
                errorMessageDuplicated += " Código,"
            }

            if (data.includes(PROVIDER_ID_DUPLICATED)) {
                errorMessageDuplicated += " Identificación,"
            }

            if (data.includes(PROVIDER_EMAIL_DUPLICATED)) {
                errorMessageDuplicated += " Correo electrónico,"
            }

            if (data.includes(PROVIDER_PHONE_NUMBER_DUPLICATED)) {
                errorMessageDuplicated += " Número de teléfono,"
            }

            if (data.includes(PROVIDER_CONTACT_EMAIL_DUPLICATED)) {
                errorMessageDuplicated += " Correo de contacto,"
            }

            if (data.includes(PROVIDER_CONTACT_PHONE_DUPLICATED)) {
                errorMessageDuplicated += " Número de contacto,"
            }

            setErrorDuplicatedData(errorMessageDuplicated.replace(/.$/, "."));
            setShowErrorDuplicatedData(true);
        }
    }

    const handleShowForm = () => {
        setShowForm(!showForm);
    }

    const handleDelete = async () => {
        clearMessages();
        const response = await deleteProvider(loggedUser.token, idToDelete);

        if (response.status === 204) {
            setShowDeleteConfirmation(false);
            setUpdateTable(!updateTable);
            setShowProviderDeleted(true);
        } else {
            // Error from API
            const { data } = response;

            if (data === PROVIDER_ASSOCIATED_WITH_STOCK) {
                setShowDeleteConfirmation(false);
                setShowErrorAssignedProvider(true);
            }
        }
    }

    const handleShowConfirmationModal = () => {
        setShowConfirmationModal(true);
    }

    const handleCloseConfirmationModal = () => {
        setShowConfirmationModal(false);
    }

    const handleResetForm = () => {
        reset();
        setShowForm(false);
        setShowConfirmationModal(false);
    }

    const clearMessages = () => {
        setShowErrorDuplicatedData(false);
        setShowProviderDeleted(false);
        setErrorDuplicatedData('');
        setShowErrorAssignedProvider(false);
    }

    // ----- Constants
    const Actions = ({ row }) => (
        <div className="d-flex">
            {checkPermission(loggedUser.permissions, 'edit_providers') &&
                <TableActionButton className="btn btn-primary">
                    <Link to={`/proveedores/${row.id}`}>
                        <MdModeEdit /> Editar
                    </Link>
                </TableActionButton>
            }
            {checkPermission(loggedUser.permissions, 'delete_providers') &&
                <TableActionButton className="btn btn-danger" onClick={() => openDeleteConfirmation(row.id)}>
                    <MdDelete />
                    Eliminar
                </TableActionButton>
            }

        </div>
    );

    const columns = [
        {
            name: 'Código',
            selector: row => row.code,
            sortable: true,
            sortField: 'code',
        },
        {
            name: 'Nombre',
            selector: row => row.name,
            sortable: true,
            sortField: 'name',
        },
        {
            name: 'Identificación',
            selector: row => row.identification,
        },
        {
            name: 'Correo electrónico',
            selector: row => row.email,
        },
        {
            name: 'Teléfono',
            selector: row => row.phoneNumber,
        },
        {
            name: 'Acciones',
            width: "210px",
            cell: row => <Actions row={row} />,
        },
    ];

    const filters = [
        {
            column: 'code',
            input: 'text',
            label: 'Código',
            operation: 'like',
            length: 20
        },
        {
            column: 'name',
            input: 'text',
            label: 'Nombre',
            operation: 'like',
            length: 50
        },
        {
            column: 'identification',
            input: 'text',
            label: 'Identificación',
            operation: 'like',
            length: 20
        },
        {
            column: 'tag',
            input: 'tags',
            label: 'Etiquetas',
            operation: 'in',
        },
    ];

    // ----- Render
    return (
        <div className="container mt-5">
            {showProviderDeleted &&
                <div className="alert alert-success text-center mb-1" role="alert">
                    Proveedor eliminado satisfactoriamente.
                </div>
            }

            {showErrorDuplicatedData &&
                <div className="alert alert-danger text-center mb-1" role="alert">
                    {errorDuplicatedData}
                </div>
            }

            {showErrorAssignedProvider &&
                <div className="alert alert-danger text-center mb-1" role="alert">
                    El proveedor no se puede eliminar porque ya es parte del inventario.
                </div>
            }

            <h1>Proveedores</h1>

            <div className="d-flex mt-3 justify-content-end">
                {checkPermission(loggedUser.permissions, 'add_providers') &&
                    <AddButton className="btn btn-primary" onClick={handleShowForm}>
                        <FaPlus /> Agregar nuevo
                    </AddButton>
                }
            </div>

            {showForm &&
                <FormContainer>
                    <form onSubmit={handleSubmit(handleCreate)}>
                        <label className="d-flex mb-1" htmlFor="code">Código</label>
                        <input className="form-control" type="text" id="code" autoComplete="off" {...register("code", { required: true, maxLength: 20 })} maxLength={20} autoFocus />
                        <span className="my-1" role="alert">
                            {errors.code?.type === "required" && `Campo requerido`}
                            {errors.code?.type === "maxLength" && `Formato inválido`}
                        </span>

                        <label className="d-flex mb-1" htmlFor="name">Nombre</label>
                        <input className="form-control" type="text" id="name" autoComplete="off" {...register("name", { required: true, maxLength: 50 })} maxLength={50} />
                        <span className="my-1" role="alert">
                            {errors.name?.type === "required" && `Campo requerido`}
                            {errors.name?.type === "maxLength" && `Formato inválido`}
                        </span>

                        <label className="d-flex mb-1" htmlFor="identification">Identificación</label>
                        <input className="form-control" type="text" id="identification" autoComplete="off" {...register("identification", { required: true, maxLength: 20 })} maxLength={20} />
                        <span className="my-1" role="alert">
                            {errors.identification?.type === "required" && `Campo requerido`}
                            {errors.identification?.type === "maxLength" && `Formato inválido`}
                        </span>

                        <label className="d-flex mb-1" htmlFor="email">Correo electrónico</label>
                        <input className="form-control" type="text" id="email" autoComplete="off" {...register("email", { required: true, maxLength: 50, pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/ })} maxLength={50} />
                        <span className="my-1" role="alert">
                            {errors.email?.type === "required" && `Campo requerido`}
                            {errors.email?.type === "maxLength" && `Formato inválido`}
                            {errors.email?.type === "pattern" && `Formato de correo incorrecto`}
                        </span>

                        <label className="d-flex mb-1" htmlFor="address">Dirección</label>
                        <input className="form-control" type="text" id="address" autoComplete="off" {...register("address", { maxLength: 100 })} maxLength={100} />
                        <span className="my-1" role="alert">
                            {errors.address?.type === "maxLength" && `Formato inválido`}
                        </span>

                        <label className="d-flex mb-1" htmlFor="phoneNumber">Número de teléfono</label>
                        <input className="form-control" type="text" id="phoneNumber" autoComplete="off" {...register("phoneNumber", { required: true, maxLength: 20 })} maxLength={20} />
                        <span className="my-1" role="alert">
                            {errors.phoneNumber?.type === "required" && `Campo requerido`}
                            {errors.phoneNumber?.type === "maxLength" && `Formato inválido`}
                        </span>

                        <label className="d-flex mb-1" htmlFor="contactName">Nombre de contacto</label>
                        <input className="form-control" type="text" id="contactName" autoComplete="off" {...register("contactName", { required: true, maxLength: 50 })} maxLength={50} />
                        <span className="my-1" role="alert">
                            {errors.contactName?.type === "required" && `Campo requerido`}
                            {errors.contactName?.type === "maxLength" && `Formato inválido`}
                        </span>

                        <label className="d-flex mb-1" htmlFor="whatsapp">Número de celular</label>
                        <input className="form-control" type="text" id="whatsapp" autoComplete="off" {...register("whatsapp", { maxLength: 20 })} maxLength={20} />
                        <span className="my-1" role="alert">
                            {errors.whatsapp?.type === "maxLength" && `Formato inválido`}
                        </span>

                        <label className="d-flex mb-1" htmlFor="contactEmail">Correo de contacto</label>
                        <input className="form-control" type="text" id="contactEmail" autoComplete="off" {...register("contactEmail", { required: true, maxLength: 50, pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/ })} maxLength={50} />
                        <span className="my-1" role="alert">
                            {errors.contactEmail?.type === "required" && `Campo requerido`}
                            {errors.contactEmail?.type === "maxLength" && `Formato inválido`}
                            {errors.contactEmail?.type === "pattern" && `Formato de correo incorrecto`}
                        </span>

                        <label className="d-flex mb-1" htmlFor="contactPhone">Número de contacto</label>
                        <input className="form-control" type="text" id="contactPhone" autoComplete="off" {...register("contactPhone", { required: true, maxLength: 20 })} maxLength={20} />
                        <span className="my-1" role="alert">
                            {errors.contactPhone?.type === "required" && `Campo requerido`}
                            {errors.contactPhone?.type === "maxLength" && `Formato inválido`}
                        </span>

                        <label className="d-flex mb-1" htmlFor="description">Descripción</label>
                        <textarea className="form-control" id="description" rows="3" maxLength={100} {...register("description", { maxLength: 100 })} />
                        <span className="my-1" role="alert">
                            {errors.description?.type === "maxLength" && `Formato inválido`}
                        </span>

                        <div className="d-flex justify-content-center mt-3">
                            <button className="btn btn-primary reset" type="button" onClick={handleShowConfirmationModal}>Cancelar</button>
                            <button className="btn btn-danger" type="submit">Guardar</button>
                        </div>

                    </form>
                </FormContainer>
            }

            <TableContainer
                columns={columns}
                fetchDataHandler={getProviders}
                updateTable={updateTable}
                filters={filters}
            />

            {showDeleteConfirmation &&
                <DeleteConfirmationModal
                    text={"Desea eliminar el proveedor?"}
                    closeHandler={handleCloseDeleteConfirmationModal}
                    actionHandler={handleDelete}
                    show={showDeleteConfirmation}
                />
            }

            {showConfirmationModal &&
                <ConfirmationModal
                    closeHandler={handleCloseConfirmationModal}
                    actionHandler={handleResetForm}
                    show={showConfirmationModal}
                />
            }

        </div>
    )
}


const FormContainer = styled.div`
    border: #dee2e6 solid 1px;
    margin: 25px auto;
    padding: 40px;
    width: 85%;

    form {
        display: grid;
    
        > label {
            font-size: 15px;
            font-weight: bold;
        }

        > span {
            color: #D04437;
            font-size: 13px;
            min-height: 12px;
        }

        div > button {
            &.reset {
                background-color: #A5A7A0;
                border-color: #A5A7A0;
                margin-right: 20px;
                
                &:active {
                    background-color: #A5A7A0;
                    border-color: #A5A7A0;
                }
            }
        }
    }
`;

export default Providers;