import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { styled } from "styled-components";

import { formatCurrency, getTotalItems } from "../../utils/numbers";
import { formatDateAndTime } from "../../utils/dates";

const IssueMovementConfirmationModal = ({ actionHandler, closeHandler, requestor, vehicle, date,
        odometer, description, details, show }) => {
    // ----- Actions
    const handleClose = () => {
        closeHandler();
    }

    const handleAction = () => {
        actionHandler();
    }

    // ----- Render
    return (
        <Modal show={show} size="lg" backdrop="static" centered onHide={handleClose}>
            <Modal.Header className='fw-bold' closeButton>
                Detalles de movimiento
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex">
                    <label className="fw-bold me-3">Fecha de despacho:</label>
                    <p>{formatDateAndTime(date)}</p>
                </div>
                <div className="d-flex">
                    <label className="fw-bold me-3">Solicitante:</label>
                    <p>{requestor}</p>
                </div>
                <div className="d-flex">
                    <label className="fw-bold me-3">Vehículo:</label>
                    <p>{vehicle}</p>
                </div>
                <div className="d-flex">
                    <label className="fw-bold me-3">Kilometraje:</label>
                    <p>{odometer}</p>
                </div>
                <div className="d-flex">
                    <label className="fw-bold me-3">Descripción:</label>
                    <p>{description}</p>
                </div>
                {details && <>
                    <div className="d-flex flex-column">
                        <table className="table table-bordered my-2">
                            <thead>
                                <tr>
                                    <th scope="col">Artículo</th>
                                    <th scope="col">Marca</th>
                                    <th scope="col">Cantidad</th>
                                    <th scope="col">Precio unitario</th>
                                </tr>
                            </thead>
                            <tbody>
                                {details.map((item, index) =>
                                (
                                    <tr key={index}>
                                        <td>{item.articleName}</td>
                                        <td>{item.brand}</td>
                                        <td>{item.amount}</td>
                                        <td>{`₡${formatCurrency(item.itemsValue)}`}</td>
                                    </tr>
                                )
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className="d-flex">
                        <label className="fw-bold me-3">Total:</label>
                        <p>{`₡${formatCurrency(getTotalItems(details))}`}</p>
                    </div>
                </>}
            </Modal.Body>
            <Modal.Footer>
                <p className='fw-bold me-3'>Desea guardar el movimiento?</p>
                <ModalButton onClick={handleClose}>
                    Cerrar
                </ModalButton>
                <Button onClick={handleAction}>
                    Guardar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

const ModalButton = styled(Button)`
    background-color: #A5A7A0;
    border-color: #A5A7A0;
    
    &:hover {
        background-color: #A5A7A0;
        border-color: #A5A7A0;
    }
`;

export default IssueMovementConfirmationModal;
