import axios from 'axios';
import { BASE_API_URL } from '../constants/env';

export const getClients = async (token, page, size, sortField, filtersData) => {
    
    const url = sortField ? `${BASE_API_URL}/clients/search?page=${page}&size=${size}&sort=${sortField}`
        : `${BASE_API_URL}/clients/search?page=${page}&size=${size}`;

    return axios.post(url, filtersData, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then((response) => {
        const { data } = response;
        return data;
    }).catch(error => {
        console.log('Error on getClients call: ', error);
        return error;
    });
}

export const getClient = async (token, id) => {
    const url = `${BASE_API_URL}/clients/${id}`;

    return axios.get(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }
    ).then(response => {
        const { data } = response;
        return data;
    }).catch(error => {
        console.log('Error on getClient call: ', error);
        return error;
    })
}

export const createClient = async (token, data) => {
    const url = `${BASE_API_URL}/clients`;
    const payload = {
        name: data.name,
        email: data.email,
        address: data.address,
        phoneNumber: data.phoneNumber,
        contactName: data.contactName,
        code: data.code,
        identification: data.identification,
        whatsapp: data.whatsapp,
        contactEmail: data.contactEmail,
        contactPhone: data.contactPhone,
        description: data.description
    };

    return axios.post(url, payload, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then((response) => {
        return response;
    }).catch(error => {
        console.log('Error on createClient call: ', error);
        return error.response;
    });
}

export const editClient = async (token, clientId, data) => {
    const url = `${BASE_API_URL}/clients/${clientId}`;
    const payload = {
        name: data.name,
        email: data.email,
        address: data.address,
        phoneNumber: data.phoneNumber,
        contactName: data.contactName,
        code: data.code,
        identification: data.identification,
        whatsapp: data.whatsapp,
        contactEmail: data.contactEmail,
        contactPhone: data.contactPhone,
        description: data.description,
    };

    return axios.put(url, payload, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then((response) => {
        return response;
    }).catch(error => {
        console.log('Error on editClient call: ', error);
        return error.response;
    });
}

export const deleteClient = async (token, userId) => {
    const url = `${BASE_API_URL}/clients/${userId}`;

    return axios.delete(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then((response) => {
        return response;
    }).catch(error => {
        console.log('Error on deleteClient call: ', error);
        return error.response;
    });
}