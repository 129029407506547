import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom"

import { styled } from "styled-components";
import { useForm } from "react-hook-form";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { MdDelete, MdModeEdit } from "react-icons/md";
import moment from 'moment';

import { useAppContext } from "../contextApi/context";
import { searchProvidersByName, getProvider } from "../services/ProvidersService";
import { searchArticlesByName } from "../services/ArticlesService";
import { createMovement } from "../services/StockMovementService";
import { TableActionButton } from "../styles/StyledComponents";
import { formatCurrency, getSubTotal, getTotalDiscount, getTotalExtraCharges, getTotalItems } from "../utils/numbers";
import ReceiptMovementConfirmationModal from "../components/shared/ReceiptMovementConfirmationModal";
import CustomDatePicker from "../components/shared/CustomDatePicker";
import ReceiptEditDetailModal from "../components/shared/ReceiptEditDetailModal";
import AssignArticleModal from "../components/shared/AssignArticleModal";
import { ONLY_NUMBERS_GREATER_THAN_ZERO, TWO_DECIMALS_GREATER_THAN_ZERO, TWO_DECIMALS_ZERO_AND_POSITIVE } from "../constants/regex";

const CreateReceiptMovement = () => {

    // ----- Context/Hooks
    const { loggedUser } = useAppContext();
    const navigate = useNavigate();
    const { register, formState: { errors }, handleSubmit } = useForm({
        defaultValues: {
            tax: '13',
        }
    });
    const { register: register2, formState: { errors: errors2 }, handleSubmit: handleSubmit2, reset: reset2 } = useForm();

    // ----- State
    const [timer, setTimer] = useState(null);
    let [details, setDetails] = useState([]);
    const [articleNow, setArticleNow] = useState(Date.now());
    const [brandNow, setBrandNow] = useState(Date.now() + 1);

    let [selectedArticleProvider, setSelectedArticleProvider] = useState(null);
    let [selectedArticle, setSelectedArticle] = useState(null);
    let [assignedArticles, setAssignedArticles] = useState([]);
    let [availableArticles, setAvailableArticles] = useState([]);
    let [showArticleError, setShowArticleError] = useState(null);

    let [availableBrands, setAvailableBrands] = useState([]);
    let [showBrandError, setShowBrandError] = useState(null);
    let [selectedBrand, setSelectedBrand] = useState(null);

    let [availableProviders, setAvailableProviders] = useState([]);
    let [selectedProvider, setSelectedProvider] = useState(null);

    let [showConfirmation, setShowConfirmation] = useState(false);
    let [payload, setPayload] = useState(null);
    let [selectedCurrency, setSelectedCurrency] = useState('CRC');
    let [currencySymbol, setCurrencySymbol] = useState('₡');

    let [tax, setTax] = useState(0);
    let [invoiceDate, setInvoiceDate] = useState(moment.utc(Date.now()).format());

    let [showEditDetailModal, setShowEditDetailModal] = useState(false);
    let [detailToEdit, setDetailToEdit] = useState(null);
    let [detailIdToEdit, setDetailIdToEdit] = useState(null);

    let [showAssignArticleModal, setShowAssignArticleModal] = useState(false);
    let [regexAmount, setRegexAmount] = useState(TWO_DECIMALS_GREATER_THAN_ZERO);


    useEffect(() => {
        setCurrencySymbol(selectedCurrency === 'CRC' ? '₡' : '$')
    }, [selectedCurrency]);


    // ----- Actions
    const handleAddDetail = async (data) => {
        if (isAllDataFilled()) {
            const newDetail = {
                id: selectedArticleProvider.id,
                providerName: selectedArticleProvider.providerName,
                articleName: selectedArticleProvider.articleName,
                brand: selectedArticleProvider.brand,
                amount: Number(data.amount),
                itemsValue: Number(data.itemsValue),
                discount: data.discount ? Number(data.discount) : 0,
                extraCharges: data.extraCharges ? Number(data.extraCharges) : 0,
                measureUnit: selectedArticleProvider.measureUnit
            };
            details.push(newDetail);
            setDetails(details);

            setAvailableArticles([]);
            setSelectedArticle(null);
            setArticleNow(Date.now());

            setAvailableBrands([]);
            setSelectedBrand(null);
            setBrandNow(Date.now() + 1);

            reset2();
        }
    }

    const handleSaveMovement = async () => {
        payload.details.forEach(item => {
            item.itemsValue = (item.itemsValue + (item.extraCharges - item.discount) / item.amount) * (1 + payload.tax / 100)
        });

        const response = await createMovement(loggedUser.token, payload);

        if (response.status === 201) {
            setShowConfirmation(false);
            navigate("/compras?created=true");
        } else {
            // Error from API
        }
    }

    const handleShowConfirmation = (data) => {
        const payload = {
            type: "RECEIPT",
            invoice: data.invoice,
            description: data.description,
            tax: Number(data.tax),
            currency: selectedCurrency,
            eventDate: invoiceDate,
            details: details.map(item => {
                return {
                    articleProviderId: item.id,
                    amount: item.amount,
                    itemsValue: item.itemsValue,
                    discount: item.discount,
                    extraCharges: item.extraCharges,
                }
            }),
        };

        setTax(data.tax);
        setPayload(payload);
        setShowConfirmation(true);
    }

    const handleCloseConfirmation = () => {
        setShowConfirmation(false);
    }

    const handleOpenDetail = (index) => {
        let detail = details[index];
        setDetailIdToEdit(index);
        setDetailToEdit(detail);
        setShowEditDetailModal(true);
    }

    const handleCloseDetail = () => {
        setDetailToEdit(null);
        setDetailIdToEdit(null);
        setShowEditDetailModal(false);
    }

    const handleEditDetail = (detail) => {
        details[detailIdToEdit] = detail;
        setShowEditDetailModal(false);
    }

    const handleAssignArticle = async (newAssignment) => {
        const providerUpdated = await getProvider(loggedUser.token, newAssignment.providerId);
        setAssignedArticles(providerUpdated.articles);

        // Reset Brands dropdown
        setBrandNow(Date.now());

        // Fill brands based on assignedArticles
        let brands = providerUpdated.articles ? providerUpdated.articles.filter(item => item.articleName === newAssignment.articleName).map(x => ({ id: x.brand, label: x.brand })) : [];

        // Remove brands already selected to details
        const brandsSelected = details.map(detail => detail.brand);
        brands = brands.filter((brand) => !brandsSelected.includes(brand.label));

        // Add option to assign new Article/Provider
        brands.push({ id: 0, label: '- Asignar artículo a proveedor' })
        setAvailableBrands(brands);

        let index = brands.findIndex(brand => brand.label === newAssignment.brand)
        setSelectedBrand(brands[index])
        
        setSelectedArticleProvider(newAssignment);
        setShowAssignArticleModal(false);
    }

    const handleForm2Errors = () => {
        isAllDataFilled();
    }

    const handleSearchProviders = (e, newValue, reason) => {
        clearTimeout(timer);

        const newTimer = setTimeout(async () => {
            if (reason === 'clear') {
                reset2({ amount: '', itemsValue: '' });
                setAvailableProviders([]);
                setSelectedProvider(null);
            }

            if (newValue !== '') {
                if (reason === 'input') {
                    clearMessages();
                    const response = await searchProvidersByName(loggedUser.token, newValue)
                    setAvailableProviders(response?.map(provider => ({ id: provider.id, label: provider.name })));
                }
            }
            else {
                reset2({ amount: '', itemsValue: '' });
                setAvailableProviders([]);
                setSelectedProvider(null);
            }
        }, 500);

        setTimer(newTimer)
    }

    const handleSelectProvider = async (event, newValue) => {
        if (newValue != null) {
            reset2({ amount: '', itemsValue: '' });

            const providerSelected = await getProvider(loggedUser.token, newValue.id);
            setSelectedProvider(providerSelected);
            setAssignedArticles(providerSelected.articles);
        } else {
            setAssignedArticles(null);
        }
    }

    const handleSearchArticles = (e, newValue, reason) => {
        clearTimeout(timer);

        const newTimer = setTimeout(async () => {
            if (reason === 'clear') {
                setAvailableArticles([]);
            }

            if (newValue !== '') {
                if (reason === 'input') {
                    clearMessages();
                    const response = await searchArticlesByName(loggedUser.token, newValue)
                    setAvailableArticles(response?.map(article => ({ id: article.id, label: article.name, measureUnit: article.measureUnit })));
                }
            }
            else {
                setAvailableArticles([]);
            }
        }, 500);

        setTimer(newTimer)
    }

    const handleSelectArticle = async (event, newValue) => {
        if (newValue != null) {
            let articleFound = availableArticles.find(article => article.id === parseInt(newValue.id));

            if (articleFound.measureUnit === "UNITS") {
                setRegexAmount(ONLY_NUMBERS_GREATER_THAN_ZERO)
            }
            else {
                setRegexAmount(TWO_DECIMALS_GREATER_THAN_ZERO);
            }

            setSelectedArticle(articleFound);

            // Reset Brands dropdown
            setBrandNow(Date.now());

            // Fill brands based on assignedArticles
            let brands = assignedArticles ? assignedArticles.filter(item => item.articleName === articleFound.label).map(x => ({ id: x.brand, label: x.brand })) : [];

            // Remove brands already selected to details
            const brandsSelected = details.map(detail => detail.brand);
            brands = brands.filter((brand) => !brandsSelected.includes(brand.label));

            // Add option to assign new Article/Provider
            brands.push({ id: 0, label: '- Asignar artículo a proveedor' })
            setAvailableBrands(brands);

            setShowArticleError(false);
        } else {
            setSelectedArticle(null);
            setBrandNow(Date.now());
        }
    }

    const handleSelectBrand = async (event, newValue) => {
        if (newValue != null) {
            if (newValue.id === 0) {
                // Show assign article modal
                setShowAssignArticleModal(true);
            }
            else {
                // When selecting Article/Provider created
                let brandFound = availableBrands.find(brand => brand.label === newValue.label);

                setSelectedBrand(brandFound.label);

                const articleName = selectedArticle.label;
                const brandName = brandFound.label;
                const articleProvider = assignedArticles.find(item => item.articleName === articleName && item.brand === brandName)
                setSelectedArticleProvider(articleProvider);

                setShowBrandError(false);
            }
        } else {
            setSelectedBrand(null);
        }
    }

    const handleDelete = (id) => {
        let deleted = details[id];
        details = details.filter(function (detail) {
            return detail !== deleted
        });
        setDetails(details);

        // Reset Brands dropdown
        setBrandNow(Date.now());
        // Fill brands based on assignedArticles
        let brands = assignedArticles ? assignedArticles.filter(item => item.articleName === selectedArticle.label).map(x => ({ id: x.brand, label: x.brand })) : [];
        // Remove brands already selected to details
        const brandsSelected = details.map(detail => detail.brand);
        brands = brands.filter((brand) => !brandsSelected.includes(brand.label));
        // Add option to assign new Article/Provider
        brands.push({ id: 0, label: '- Asignar artículo a proveedor' })
        setAvailableBrands(brands);
    }

    const handleCurrencyChange = (e) => {
        setSelectedCurrency(e.target.value);
    }

    const isAllDataFilled = () => {
        clearMessages();
        let allGood = true;

        if (selectedArticle === null) {
            allGood = false;
            setShowArticleError(true);
        }

        if (selectedBrand === null) {
            allGood = false;
            setShowBrandError(true);
        }

        return allGood;
    }

    const clearMessages = () => {
        setShowArticleError(false);
        setShowBrandError(false);
    }

    // ----- Render
    return (
        <div className="container mt-4">

            <div className="mb-3">
                <Link to="/compras">Volver a página de compras</Link>
            </div>

            <h1 className="mb-5">Crear compra</h1>

            <ContainerStyled>
                <h3 className="mt-4">Datos generales</h3>

                <form onSubmit={handleSubmit(handleShowConfirmation)}>

                    <label className="d-flex mb-1" htmlFor="invoice">Tipo de moneda</label>
                    <div className="toggle_options mb-3">
                        <input type="radio" className="btn-check" name="options" id="crc" value={'CRC'} onChange={handleCurrencyChange} disabled={details.length > 0} defaultChecked />
                        <label className="btn btn-outline-success crc" htmlFor="crc">Colones</label>

                        <input type="radio" className="btn-check" name="options" id="usd" value={'USD'} onChange={handleCurrencyChange} disabled={details.length > 0} />
                        <label className="btn btn-outline-success usd" htmlFor="usd">Dólares</label>
                    </div>

                    <label className="d-flex mb-1" htmlFor="invoice">Factura</label>
                    <input className="form-control" type="text" id="invoice" autoComplete="off" maxLength={10} {...register("invoice", { required: true, maxLength: 10 })} />
                    <span className="my-1" role="alert">
                        {errors.invoice?.type === "required" && `Campo requerido`}
                        {errors.invoice?.type === "maxLength" && `Formato inválido`}
                    </span>

                    <label className="d-flex mb-1">Fecha de factura</label>
                    <CustomDatePicker defaultDate={moment(Date.now())} minDate={moment(Date.now()).subtract(5, 'months')} maxDate={moment(Date.now())} actionHandler={(date) => setInvoiceDate(date)} />
                    <span className="my-1" />

                    <label className="d-flex mb-1" htmlFor="description">Descripción</label>
                    <textarea className="form-control" id="description" maxLength={100} rows="3" {...register("description", { maxLength: 100 })} />
                    <span className="my-1" role="alert">
                        {errors.description?.type === "maxLength" && `Formato inválido`}
                    </span>

                    <label className="d-flex mb-1" htmlFor="tax">Impuesto a aplicar (IVA %)</label>
                    <input className="form-control" type="text" id="tax" autoComplete="off" maxLength={10} {...register("tax", { required: true, maxLength: 10, pattern: /(^100([.]0{1,2})?)$|(^\d{1,2}([.]\d{1,2})?)$/ })} />
                    <span className="my-1" role="alert">
                        {errors.tax?.type === "required" && `Campo requerido`}
                        {errors.tax?.type === "maxLength" && `Formato inválido`}
                        {errors.tax?.type === "pattern" && `Sólo se permiten números entre 0 y 100`}
                    </span>

                    <label className="d-flex mb-1">Proveedor</label>
                    <Autocomplete
                        disablePortal
                        id="size-small-outlined"
                        size="small"
                        options={availableProviders}
                        renderInput={(params) => <TextField {...params} label="Buscar" />}
                        onChange={handleSelectProvider}
                        onInputChange={handleSearchProviders}
                        noOptionsText={"Buscar por nombre"}
                        className="mt-1"
                        disabled={details.length > 0}
                    />

                    {details.length > 0 &&
                        <button className="btn btn-danger mt-3 save-receipt" type="submit">Guardar</button>
                    }
                </form>
            </ContainerStyled>

            {selectedProvider &&
                <ContainerStyled>
                    <hr className="mt-3" />

                    <h3 className="mt-4">Detalles de movimiento</h3>

                    <form onSubmit={handleSubmit2(handleAddDetail, handleForm2Errors)}>
                        <label className="d-flex mb-1">Artículo</label>
                        <Autocomplete
                            key={articleNow}
                            disablePortal
                            id="size-small-outlined"
                            size="small"
                            options={availableArticles}
                            renderInput={(params) => <TextField {...params} label="Buscar" />}
                            onChange={handleSelectArticle}
                            onInputChange={handleSearchArticles}
                            noOptionsText={"Buscar por nombre"}
                            className="mt-1"
                        />
                        <span className="my-1" role="alert">
                            {showArticleError && `Campo requerido`}
                        </span>

                        <label className="d-flex mb-1">Marca</label>
                        <Autocomplete
                            key={brandNow}
                            disablePortal
                            id="size-small-outlined"
                            size="small"
                            options={availableBrands}
                            renderInput={(params) => <TextField {...params} label="Buscar" />}
                            onChange={handleSelectBrand}
                            // onInputChange={handleSearchBrands}
                            noOptionsText={"Buscar por nombre"}
                            className="mt-1"
                            disabled={!selectedArticle}
                            value={selectedBrand}
                        />
                        <span className="my-1" role="alert">
                            {showBrandError && `Campo requerido`}
                        </span>

                        <label className="d-flex mb-1" htmlFor="amount">Cantidad</label>
                        <input className="form-control" type="text" id="amount" autoComplete="off" maxLength={10} {...register2("amount", { required: true, maxLength: 10, pattern: regexAmount })} />
                        <span className="my-1" role="alert">
                            {errors2.amount?.type === "required" && `Campo requerido`}
                            {errors2.amount?.type === "maxLength" && `Formato inválido`}
                            {errors2.amount?.type === "pattern" && selectedArticle?.measureUnit !== "UNITS" && `Sólo se permiten números (5 - 3.50)`}
                            {errors2.amount?.type === "pattern" && selectedArticle?.measureUnit === "UNITS" && `Sólo se permiten números enteros (5 - 100)`}
                        </span>

                        <label className="d-flex mb-1" htmlFor="itemsValue">Valor por unidad</label>
                        <input className="form-control" type="text" id="itemsValue" autoComplete="off" maxLength={10} {...register2("itemsValue", { required: true, maxLength: 10, pattern: TWO_DECIMALS_GREATER_THAN_ZERO })} />
                        <span className="my-1" role="alert">
                            {errors2.itemsValue?.type === "required" && `Campo requerido`}
                            {errors2.itemsValue?.type === "maxLength" && `Formato inválido`}
                            {errors2.itemsValue?.type === "pattern" && `Sólo se permiten números (5 - 3.50)`}
                        </span>

                        <label className="d-flex mb-1" htmlFor="discount">Descuento</label>
                        <input className="form-control" type="text" id="discount" autoComplete="off" maxLength={10} {...register2("discount", { maxLength: 10, pattern: TWO_DECIMALS_ZERO_AND_POSITIVE })} />
                        <span className="my-1" role="alert">
                            {errors2.discount?.type === "maxLength" && `Formato inválido`}
                            {errors2.discount?.type === "pattern" && `Sólo se permiten números (5 - 3.50)`}
                        </span>

                        <label className="d-flex mb-1" htmlFor="extraCharges">Otros cargos</label>
                        <input className="form-control" type="text" id="extraCharges" autoComplete="off" maxLength={10} {...register2("extraCharges", { maxLength: 10, pattern: TWO_DECIMALS_ZERO_AND_POSITIVE })} />
                        <span className="my-1" role="alert">
                            {errors2.extraCharges?.type === "maxLength" && `Formato inválido`}
                            {errors2.extraCharges?.type === "pattern" && `Sólo se permiten números (5 - 3.50)`}
                        </span>

                        <button className="btn btn-danger mt-3" type="submit">Agregar detalle</button>
                    </form>

                    {details.length > 0 &&
                        <>
                            <div className="d-flex justify-content-center">
                                <table className="table table-bordered mt-3">
                                    <thead>
                                        <tr>
                                            <th scope="col">Artículo</th>
                                            <th scope="col">Marca</th>
                                            <th scope="col">Cantidad</th>
                                            <th scope="col">Valor por unidad</th>
                                            <th scope="col">Descuento</th>
                                            <th scope="col">Otros cargos</th>
                                            <th scope="col">Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {details.map((detail, index) =>
                                        (
                                            <tr key={index}>
                                                <td>{detail.articleName}</td>
                                                <td>{detail.brand}</td>
                                                <td>{detail.amount}</td>
                                                <td>{`${currencySymbol}${formatCurrency(detail.itemsValue)}`}</td>
                                                <td>{`${currencySymbol}${formatCurrency(detail.discount)}`}</td>
                                                <td>{`${currencySymbol}${formatCurrency(detail.extraCharges)}`}</td>
                                                <td className="d-flex">
                                                    <TableActionButton className="btn btn-primary" onClick={() => handleOpenDetail(index)}>
                                                        <MdModeEdit /> Editar
                                                    </TableActionButton>
                                                    <TableActionButton className="btn btn-danger" onClick={() => handleDelete(index)}>
                                                        <MdDelete />
                                                        Eliminar
                                                    </TableActionButton>
                                                </td>
                                            </tr>
                                        )
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <hr />
                            <div className="d-flex justify-content-end">
                                <div className="me-5 text-center">
                                    <label className="fw-bold">Subtotal</label>
                                    <p className="mb-1">{`${currencySymbol}${formatCurrency(getTotalItems(details))}`}</p>
                                </div>
                                <div className="me-5 text-center">
                                    <label className="fw-bold">Descuentos</label>
                                    <p className="mb-1">{`${currencySymbol}${formatCurrency(getTotalDiscount(details))}`}</p>
                                </div>
                                <div className="me-5 text-center">
                                    <label className="fw-bold">Otros cargos</label>
                                    <p className="mb-1">{`${currencySymbol}${formatCurrency(getTotalExtraCharges(details))}`}</p>
                                </div>
                                <div className="me-5 text-center">
                                    <label className="fw-bold">Total sin impuestos</label>
                                    <p className="mb-1">{`${currencySymbol}${formatCurrency(getSubTotal(details))}`}</p>
                                </div>
                            </div>
                        </>
                    }
                </ContainerStyled >
            }

            {showConfirmation &&
                <ReceiptMovementConfirmationModal
                    details={details}
                    tax={tax}
                    data={payload}
                    currencySymbol={currencySymbol}
                    closeHandler={handleCloseConfirmation}
                    actionHandler={handleSaveMovement}
                    show={showConfirmation}
                    provider={selectedProvider}
                />
            }

            {showEditDetailModal &&
                <ReceiptEditDetailModal
                    detail={detailToEdit}
                    closeHandler={handleCloseDetail}
                    actionHandler={handleEditDetail}
                    show={showEditDetailModal}
                />
            }

            {showAssignArticleModal &&
                <AssignArticleModal
                    provider={selectedProvider}
                    article={selectedArticle}
                    closeHandler={() => setShowAssignArticleModal(false)}
                    actionHandler={handleAssignArticle}
                    show={showAssignArticleModal}
                />
            }

        </div>
    );
}

// ----- Styles
const ContainerStyled = styled.div`
    margin-bottom: 100px;
    
    form {
        border: #dee2e6 solid 1px;
        display: grid;
        margin: 30px auto;
        padding: 30px;
        width: 50%;
        
        > label {
            font-size: 15px;
            font-weight: bold;
        }

        > span {
            color: #D04437;
            font-size: 13px;
            min-height: 12px;

            &.info {
                color: green;
                font-weight: 500;
            }
        }

        .save-receipt {
            bottom: 35px;
            padding-left: 40px;
            padding-right: 40px;
            position: fixed;
            right: 50px;
            z-index: 1;
        }

        .MuiAutocomplete-root {
            width: 100%;
        }

        .toggle_options {
            .btn-check:checked+.btn {
                background-color: #D04437;
                border-color: #D04437;
                
                &:hover {
                    border-color: #D04437;
                    color: #fff;
                }
            }

            > label {
                border-color: #D04437;
                color: #D04437;

                &:hover {
                    border-color: #D04437;
                    color: #D04437;
                }
            }
            
            .crc {
                border-bottom-right-radius: 0;
                border-top-right-radius: 0;
            }

            .usd {
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
            }
        }
    }

    button.btn-danger {
        background-color: #D04437;
    }
`;

export default CreateReceiptMovement;
