import { useState } from "react"
import { Link } from "react-router-dom"
import { useForm } from "react-hook-form"
import { styled } from "styled-components"

import { MdDelete } from "react-icons/md";
import { MdModeEdit } from "react-icons/md";
import { FaPlus } from "react-icons/fa";

import { createLocation, deleteLocation, getLocations } from "../services/LocationsService"
import { useAppContext } from "../contextApi/context"
import { LOCATION_NAME_DUPLICATED } from "../constants/apiMessages"
import { checkPermission } from "../utils/permissions";
import DeleteConfirmationModal from "../components/shared/DeleteConfirmationModal"
import ConfirmationModal from "../components/shared/ConfirmationModal"
import { AddButton, TableActionButton } from "../styles/StyledComponents";
import TableContainer from "../components/shared/TableContainer";


const Locations = () => {
    // ----- Context/Hooks
    const { loggedUser } = useAppContext();

    // ----- State
    const [idToDelete, setIdToDelete] = useState(null)
    const [showForm, setShowForm] = useState(false)
    const [showLocationCreated, setShowLocationCreated] = useState(false)
    const [showLocationDeleted, setShowLocationDeleted] = useState(false)
    const [showErrorDuplicatedData, setShowErrorDuplicatedData] = useState(false)
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [errorDuplicatedData, setErrorDuplicatedData] = useState('')
    const [updateTable, setUpdateTable] = useState(false);

    // ----- Hooks
    const { register, reset, handleSubmit, formState: { errors } } = useForm();

    // ----- Actions
    const handleCloseDeleteConfirmationModal = () => {
        setIdToDelete(null);
        setShowDeleteConfirmation(false);
    }

    const openDeleteConfirmation = (articleId) => {
        setIdToDelete(articleId);
        setShowDeleteConfirmation(true)
    }

    const handleCreate = async (data) => {
        clearMessages();

        const response = await createLocation(loggedUser.token, data);

        if (response.status === 201) {
            setShowLocationCreated(true);
            reset();
            setShowForm(false);
            setUpdateTable(!updateTable);
        } else {
            // Error from API
            const { data } = response;
            let errorMessageDuplicated = "La ubicación contiene datos que ya se encuentran registrados:";

            if (data.includes(LOCATION_NAME_DUPLICATED)) {
                errorMessageDuplicated += " Nombre,"
            }

            setErrorDuplicatedData(errorMessageDuplicated.replace(/.$/, "."));
            setShowErrorDuplicatedData(true);

        }
    }

    const handleShowForm = () => {
        setShowForm(!showForm);
    }

    const handleDelete = async () => {
        clearMessages();
        const response = await deleteLocation(loggedUser.token, idToDelete);

        if (response.status === 204) {
            setShowDeleteConfirmation(false);
            setUpdateTable(!updateTable);
            setShowLocationDeleted(true);
        } else {
            // Error from API
        }
    }

    const handleShowConfirmationModal = () => {
        setShowConfirmationModal(true);
    }

    const handleCloseConfirmationModal = () => {
        setShowConfirmationModal(false);
    }

    const handleResetForm = () => {
        reset();
        setShowForm(false);
        setShowConfirmationModal(false);
    }

    const clearMessages = () => {
        setShowErrorDuplicatedData(false);
        setShowLocationDeleted(false);
        setErrorDuplicatedData('');
        setShowLocationCreated(false);
    }

    // ----- Constants
    const Actions = ({ row }) => (
        <div className="d-flex">
            {checkPermission(loggedUser.permissions, 'edit_locations') &&
                <TableActionButton className="btn btn-primary">
                    <Link to={`/ubicaciones/${row.id}`}>
                        <MdModeEdit /> Editar
                    </Link>
                </TableActionButton>
            }
            {checkPermission(loggedUser.permissions, 'delete_locations') &&
                <TableActionButton className="btn btn-danger" onClick={() => openDeleteConfirmation(row.id)}>
                    <MdDelete />
                    Eliminar
                </TableActionButton>
            }
        </div>
    );

    const columns = [
        {
            name: 'Nombre',
            selector: row => row.name,
            sortable: true,
            sortField: 'name',
        },
        {
            name: 'Acciones',
            width: "215px",
            cell: row => <Actions row={row} />,
        },
    ];

    const filters = [
        {
            column: 'name',
            input: 'text',
            label: 'Nombre',
            operation: 'like',
            length: 50
        },
    ];

    // ----- Render
    return (
        <div className="container mt-5">
            {showLocationCreated &&
                <div className="alert alert-success text-center mb-1" role="alert">
                    Ubicación creada satisfactoriamente.
                </div>
            }
            
            {showLocationDeleted &&
                <div className="alert alert-success text-center mb-1" role="alert">
                    Ubicación eliminada satisfactoriamente.
                </div>
            }

            {showErrorDuplicatedData &&
                <div className="alert alert-danger text-center mb-1" role="alert">
                    {errorDuplicatedData}
                </div>
            }

            <h1>Ubicaciones</h1>

            <div className="d-flex mt-3 justify-content-end">
                {checkPermission(loggedUser.permissions, 'add_locations') &&
                    <AddButton className="btn btn-primary" onClick={handleShowForm}>
                        <FaPlus /> Agregar nueva
                    </AddButton>
                }
            </div>

            {showForm &&
                <FormContainer>
                    <form onSubmit={handleSubmit(handleCreate)}>
                        <label className="d-flex mb-1" htmlFor="name">Nombre</label>
                        <input className="form-control" type="text" id="name" autoComplete="off" maxLength={50} {...register("name", { required: true, maxLength: 50 })} autoFocus />
                        <span className="my-1" role="alert">
                            {errors.name?.type === "required" && `Campo requerido`}
                            {errors.name?.type === "maxLength" && `Formato inválido`}
                        </span>

                        <div className="d-flex justify-content-center mt-3">
                            <button className="btn btn-primary reset" type="button" onClick={handleShowConfirmationModal}>Cancelar</button>
                            <button className="btn btn-danger" type="submit">Guardar</button>
                        </div>
                    </form>
                </FormContainer>
            }

            <TableContainer
                columns={columns}
                fetchDataHandler={getLocations}
                updateTable={updateTable}
                filters={filters}
            />

            {showDeleteConfirmation &&
                <DeleteConfirmationModal
                    text={"Desea eliminar la ubicación?"}
                    closeHandler={handleCloseDeleteConfirmationModal}
                    actionHandler={handleDelete}
                    show={showDeleteConfirmation}
                />
            }

            {showConfirmationModal &&
                <ConfirmationModal
                    closeHandler={handleCloseConfirmationModal}
                    actionHandler={handleResetForm}
                    show={showConfirmationModal}
                />
            }
        </div>
    )
}


const FormContainer = styled.div`
    border: #dee2e6 solid 1px;
    margin: 25px auto;
    padding: 40px;
    width: 60%;

    form {
        display: grid;
    
        > label {
            font-size: 15px;
            font-weight: bold;
        }

        > span {
            color: #D04437;
            font-size: 13px;
            min-height: 12px;
        }

        div > button {
            &.reset {
                background-color: #A5A7A0;
                border-color: #A5A7A0;
                margin-right: 20px;

                &:active {
                    background-color: #A5A7A0;
                    border-color: #A5A7A0;
                }
            }
        }

        > textarea {
            resize: none;
        }
    }
`;

export default Locations;