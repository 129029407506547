import { useCallback, useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import { useAppContext } from "../../contextApi/context";
import { getMovementDetails } from "../../services/StockMovementService";
import { formatDate, formatDateAndTime } from "../../utils/dates"
import { formatCurrency } from "../../utils/numbers";
import { movementType } from "../../utils/translation"

const StockMovementDetailsModal = ({ closeHandler, movementId, show }) => {
    // ----- Context
    const { loggedUser } = useAppContext();

    // ----- State
    let [movementDetails, setMovementDetails] = useState(null);

    // ----- API Fetch Actions
    const fetchStockMovementDetails = useCallback(async () => {
        return await getMovementDetails(loggedUser.token, movementId);
    }, [loggedUser.token, movementId]);

    // ----- Hooks
    useEffect(() => {
        fetchStockMovementDetails().then(data => {
            // console.log('Response MODAL: ', data);
            setMovementDetails(data);
        })
    }, [fetchStockMovementDetails]);

    // ----- Actions
    const handleClose = () => {
        setMovementDetails(null);
        closeHandler();
    }

    // ----- Render
    return (
        <Modal show={show} size="lg" backdrop="static" centered onHide={handleClose}>
            <Modal.Header className='fw-bold' closeButton>
                Detalles de movimiento
            </Modal.Header>
            <Modal.Body>
                {movementDetails && <>
                    <div className="d-flex">
                        <label className="fw-bold me-3">Tipo:</label>
                        <p>{movementType(movementDetails?.type)}</p>
                    </div>
                    <div className="d-flex">
                        <label className="fw-bold me-3">Fecha de registro:</label>
                        <p>{formatDateAndTime(movementDetails?.createdDate)}</p>
                    </div>
                    <div className="d-flex">
                        <label className="fw-bold me-3">Hecho por:</label>
                        <p>{`${movementDetails?.user?.firstName} ${movementDetails?.user?.lastName} (${movementDetails?.user?.username})`}</p>
                    </div>

                    {movementDetails?.type === "RECEIPT" &&
                        <>
                            <div className="d-flex">
                                <label className="fw-bold me-3">Factura:</label>
                                <p>{movementDetails?.invoice} {movementDetails.eventDate && `(${formatDate(movementDetails.eventDate)})`}</p>
                            </div>
                            <div className="d-flex">
                                <label className="fw-bold me-3">Impuesto aplicado:</label>
                                <p>{`${movementDetails?.tax}%`}</p>
                            </div>
                            <div className="d-flex">
                                <label className="fw-bold me-3">Moneda:</label>
                                <p>{movementDetails.currency === 'CRC' ? 'Colón (₡)' : 'Dólar ($)'}</p>
                            </div>
                            {movementDetails.currency === 'USD' && 
                                <div className="d-flex">
                                    <label className="fw-bold me-3">Tipo de cambio:</label>
                                    <p>{`₡${formatCurrency(movementDetails.referenceValue)}`}</p>
                                </div>
                            }
                            <div className="d-flex">
                                <label className="fw-bold me-3">Proveedor:</label>
                                <p>{movementDetails?.details[0]?.providerName}</p>
                            </div>
                        </>
                    }

                    {movementDetails?.type === "ISSUE" &&
                        <>
                            <div className="d-flex">
                                <label className="fw-bold me-3">Fecha de despacho:</label>
                                <p>{movementDetails.eventDate ? formatDateAndTime(movementDetails.eventDate) : '-'}</p>
                            </div>
                            <div className="d-flex">
                                <label className="fw-bold me-3">Vehículo:</label>
                                <p>{`${movementDetails?.destination.make} - ${movementDetails?.destination.model} (${movementDetails?.destination.plate})`}</p>
                            </div>
                            <div className="d-flex">
                                <label className="fw-bold me-3">Recibido por:</label>
                                <p>{`${movementDetails?.receivingUser?.firstName} ${movementDetails?.receivingUser?.lastName} (${movementDetails?.receivingUser?.username})`}</p>
                            </div>
                        </>
                    }

                    {movementDetails.description &&
                        <div className="d-flex">
                            <label className="fw-bold me-3">Descripción:</label>
                            <p>{movementDetails.description}</p>
                        </div>
                    }

                    <hr />

                    <div className="d-flex flex-column">
                        <label className="fw-bold">Detalles</label>
                        <table className="table table-bordered my-2">
                            <thead>
                                <tr>
                                    <th scope="col">Artículo</th>
                                    <th scope="col">Marca</th>
                                    <th scope="col">Cantidad</th>
                                    <th scope="col">
                                        Precio unitario {movementDetails?.type === "RECEIPT" && ' (Impuesto incluido)'}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {movementDetails?.details?.map((item, index) =>
                                (
                                    <tr key={index}>
                                        <td>{item.articleName}</td>
                                        <td>{item.brand}</td>
                                        <td>{item.amount}</td>
                                        <td>{`₡${formatCurrency(item.itemsValue)}`}</td>
                                    </tr>
                                )
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className="d-flex">
                        <label className="fw-bold me-3">Valor total:</label>
                        <p>{`₡${formatCurrency(movementDetails?.totalValue)}`}</p>
                    </div>
                </>}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleClose}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default StockMovementDetailsModal;
