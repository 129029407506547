import { useCallback, useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"

import { useForm } from "react-hook-form"
import { styled } from "styled-components"
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { getProvider, editProvider, addTag, removeTag } from "../services/ProvidersService"
import { searchTagsByName } from "../services/TagsService"
import { useAppContext } from "../contextApi/context"
import {
    PROVIDER_CODE_DUPLICATED,
    PROVIDER_ID_DUPLICATED,
    PROVIDER_CONTACT_PHONE_DUPLICATED,
    PROVIDER_EMAIL_DUPLICATED,
    PROVIDER_CONTACT_EMAIL_DUPLICATED,
    PROVIDER_PHONE_NUMBER_DUPLICATED
} from "../constants/apiMessages";
import TagChip from "../components/shared/TagChip"
import AssignArticle from "../components/AssignArticle";


const EditProvider = () => {
    // ----- Context/Hooks
    const { loggedUser } = useAppContext();
    const { id } = useParams();

    // ----- State
    let [availableTags, setAvailableTags] = useState([]);
    const [currentProvider, setCurrentProvider] = useState(null);
    const [showProviderEdited, setShowProviderEdited] = useState(false);
    const [showErrorDuplicatedData, setShowErrorDuplicatedData] = useState(false);
    const [errorDuplicatedData, setErrorDuplicatedData] = useState('');
    const [selectedTag, setSelectedTag] = useState("");

    const [timer, setTimer] = useState(null);

    // ----- API Fetch Actions
    const fetchProviderData = useCallback(async () => {
        return await getProvider(loggedUser.token, id);
    }, [loggedUser.token, id]);

    // ----- Hooks
    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        defaultValues: {
            name: '',
            email: '',
            address: '',
            phoneNumber: '',
            contactName: '',
            code: '',
            identification: '',
            whatsapp: '',
            contactEmail: '',
            contactPhone: '',
            description: '',
        }
    });

    useEffect(() => {
        handleUpdateProvider()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // ----- Actions
    const handleUpdateProvider = () => {
        fetchProviderData().then(data => {
            setCurrentProvider(data);
            reset(data);
        });
    }

    const handleEdit = async (data) => {
        clearMessages();
        const response = await editProvider(loggedUser.token, currentProvider.id, data);

        if (response.status === 200) {
            setShowProviderEdited(true);
        } else {
            const { data } = response;
            let errorMessageDuplicated = "El proveedor contiene datos que ya se encuentran registrados:";

            if (data.includes(PROVIDER_CODE_DUPLICATED)) {
                errorMessageDuplicated += " Código,"
            }

            if (data.includes(PROVIDER_ID_DUPLICATED)) {
                errorMessageDuplicated += " Identificación,"
            }

            if (data.includes(PROVIDER_EMAIL_DUPLICATED)) {
                errorMessageDuplicated += " Correo electrónico,"
            }

            if (data.includes(PROVIDER_PHONE_NUMBER_DUPLICATED)) {
                errorMessageDuplicated += " Número de teléfono,"
            }

            if (data.includes(PROVIDER_CONTACT_EMAIL_DUPLICATED)) {
                errorMessageDuplicated += " Correo de contacto,"
            }

            if (data.includes(PROVIDER_CONTACT_PHONE_DUPLICATED)) {
                errorMessageDuplicated += " Número de contacto,"
            }

            setErrorDuplicatedData(errorMessageDuplicated.replace(/.$/, "."));
            setShowErrorDuplicatedData(true);
        }
    }

    const handleChange = (event, newValue) => {
        if (newValue != null) {
            setSelectedTag(newValue.value)
        }
    }

    const handleSelectOption = (e, newValue, reason) => {
        clearTimeout(timer)

        const newTimer = setTimeout(async () => {

            if (reason === 'clear') {
                setAvailableTags([]);
            }

            if (newValue !== '') {
                if (reason === 'input') {
                    const response = await searchTagsByName(loggedUser.token, newValue);
                    const tagExists = response.find(e => e.name === newValue);
                    const parsedResponse = response?.map(tag => ({ id: tag.id, label: tag.name, value: tag.name }));
                    const finalOptions = tagExists ? parsedResponse : [{ label: '- Etiqueta nueva: ' + newValue, value: newValue }, ...parsedResponse];

                    setAvailableTags(finalOptions);
                }
            }
            else {
                setAvailableTags([]);
            }
        }, 500);

        setTimer(newTimer)
    }

    const handleAddTag = async () => {
        clearMessages();

        if (selectedTag !== "") {
            let response = await addTag(loggedUser.token, currentProvider.id, selectedTag);
            if (response.status === 200) {
                setCurrentProvider(response.data);
                setSelectedTag('');
            }
        }
    }

    const handleRemoveTag = async (tagId, _name) => {
        let response = await removeTag(loggedUser.token, currentProvider.id, tagId);

        if (response.status === 200) {
            setCurrentProvider(response.data);
        }
    }

    const clearMessages = () => {
        setShowProviderEdited(false);
        setShowErrorDuplicatedData(false);
        setErrorDuplicatedData('');
    }

    // ----- Render
    return (
        <div className="container mt-4">

            <div className="mb-3">
                <Link to="/proveedores">Volver a página de proveedores</Link>
            </div>
            <h1 className="mt-4">Editar Proveedor</h1>

            {showProviderEdited &&
                <div className="alert alert-success text-center mb-1" role="alert">
                    Proveedor editado satisfactoriamente.
                </div>
            }

            {showErrorDuplicatedData &&
                <div className="alert alert-danger text-center mb-1" role="alert">
                    {errorDuplicatedData}
                </div>
            }

            <FormContainer>
                <form onSubmit={handleSubmit(handleEdit)}>
                    <label className="d-flex mb-1" htmlFor="code">Código</label>
                    <input className="form-control" type="text" id="code" autoComplete="off" {...register("code", { required: true, maxLength: 20 })} maxLength={20} autoFocus />
                    <span className="my-1" role="alert">
                        {errors.code?.type === "required" && `Campo requerido`}
                        {errors.code?.type === "maxLength" && `Formato inválido`}
                    </span>

                    <label className="d-flex mb-1" htmlFor="name">Nombre</label>
                    <input className="form-control" type="text" id="name" autoComplete="off" {...register("name", { required: true, maxLength: 50 })} maxLength={50} />
                    <span className="my-1" role="alert">
                        {errors.name?.type === "required" && `Campo requerido`}
                        {errors.name?.type === "maxLength" && `Formato inválido`}
                    </span>

                    <label className="d-flex mb-1" htmlFor="identification">Identificación</label>
                    <input className="form-control" type="text" id="identification" autoComplete="off" {...register("identification", { required: true, maxLength: 20 })} maxLength={20} />
                    <span className="my-1" role="alert">
                        {errors.identification?.type === "required" && `Campo requerido`}
                        {errors.identification?.type === "maxLength" && `Formato inválido`}
                    </span>

                    <label className="d-flex mb-1" htmlFor="email">Correo electrónico</label>
                    <input className="form-control" type="text" id="email" autoComplete="off" {...register("email", { required: true, maxLength: 50, pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/ })} maxLength={50} />
                    <span className="my-1" role="alert">
                        {errors.email?.type === "required" && `Campo requerido`}
                        {errors.email?.type === "maxLength" && `Formato inválido`}
                        {errors.email?.type === "pattern" && `Formato de correo incorrecto`}
                    </span>

                    <label className="d-flex mb-1" htmlFor="address">Dirección</label>
                    <input className="form-control" type="text" id="address" autoComplete="off" {...register("address", { maxLength: 100 })} maxLength={100} />
                    <span className="my-1" role="alert">
                        {errors.address?.type === "maxLength" && `Formato inválido`}
                    </span>

                    <label className="d-flex mb-1" htmlFor="phoneNumber">Número de teléfono</label>
                    <input className="form-control" type="text" id="phoneNumber" autoComplete="off" {...register("phoneNumber", { required: true, maxLength: 20 })} maxLength={20} />
                    <span className="my-1" role="alert">
                        {errors.phoneNumber?.type === "required" && `Campo requerido`}
                        {errors.phoneNumber?.type === "maxLength" && `Formato inválido`}
                    </span>

                    <label className="d-flex mb-1" htmlFor="contactName">Nombre de contacto</label>
                    <input className="form-control" type="text" id="contactName" autoComplete="off" {...register("contactName", { required: true, maxLength: 50 })} maxLength={50} />
                    <span className="my-1" role="alert">
                        {errors.contactName?.type === "required" && `Campo requerido`}
                        {errors.contactName?.type === "maxLength" && `Formato inválido`}
                    </span>

                    <label className="d-flex mb-1" htmlFor="whatsapp">Número de celular</label>
                    <input className="form-control" type="text" id="whatsapp" autoComplete="off" {...register("whatsapp", { maxLength: 20 })} maxLength={20} />
                    <span className="my-1" role="alert">
                        {errors.whatsapp?.type === "maxLength" && `Formato inválido`}
                    </span>

                    <label className="d-flex mb-1" htmlFor="contactEmail">Correo de contacto</label>
                    <input className="form-control" type="text" id="contactEmail" autoComplete="off" {...register("contactEmail", { required: true, maxLength: 50, pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/ })} maxLength={50} />
                    <span className="my-1" role="alert">
                        {errors.contactEmail?.type === "required" && `Campo requerido`}
                        {errors.contactEmail?.type === "maxLength" && `Formato inválido`}
                        {errors.contactEmail?.type === "pattern" && `Formato de correo incorrecto`}
                    </span>

                    <label className="d-flex mb-1" htmlFor="contactPhone">Número de contacto</label>
                    <input className="form-control" type="text" id="contactPhone" autoComplete="off" {...register("contactPhone", { required: true, maxLength: 20 })} maxLength={20} />
                    <span className="my-1" role="alert">
                        {errors.contactPhone?.type === "required" && `Campo requerido`}
                        {errors.contactPhone?.type === "maxLength" && `Formato inválido`}
                    </span>

                    <label className="d-flex mb-1" htmlFor="description">Descripción</label>
                    <textarea className="form-control" id="description" rows="3" {...register("description", { maxLength: 100 })} maxLength={100} />
                    <span className="my-1" role="alert">
                        {errors.description?.type === "maxLength" && `Formato inválido`}
                    </span>
                    <div className="d-flex justify-content-center">
                        <button className="btn btn-danger mt-3" type="submit">Guardar cambios</button>
                    </div>
                </form>
            </FormContainer>

            <hr />
            <TagsContainer>
                <h1>Etiquetas</h1>

                <div className="row my-4">
                    <div className="col pe-5">
                        <h5 className="mt-3">Agregar etiquetas</h5>

                        <Autocomplete
                            value={selectedTag}
                            disablePortal
                            id="size-small-outlined"
                            size="small"
                            options={availableTags}
                            sx={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} inputProps={{ ...params.inputProps, maxLength: 50 }} label="Buscar o crear etiqueta" />}
                            onChange={handleChange}
                            onInputChange={handleSelectOption}
                            className="mt-4 mb-3"
                            freeSolo
                        />

                        <button className="btn btn-danger" onClick={handleAddTag}>Agregar</button>
                    </div>
                </div>

                <div className="d-flex mb-1">
                    {currentProvider?.tags.map(tag =>
                        <TagChip key={tag.id} removeHandler={handleRemoveTag} name={tag.name} id={tag.id} />
                    )}
                </div>
            </TagsContainer>

            <hr />
            <AssignArticle updateHandler={handleUpdateProvider} providerId={currentProvider?.id} articles={currentProvider?.articles === null ? [] : currentProvider?.articles} />

        </div>
    )
}

const FormContainer = styled.div`
    margin: 25px auto 0;
    padding: 40px 40px 20px;
    width: 70%;

    form {
        display: grid;
    
        > label {
            font-size: 15px;
            font-weight: bold;
        }

        > span {
            color: #D04437;
            font-size: 13px;
            min-height: 12px;
        }

        button {
            background-color: #D04437;
            width: 30%;
        }
    }
`;

const TagsContainer = styled.div`
    padding: 15px 0;

    span {
        color: #D04437;
        font-size: 13px;
        min-height: 12px;
    }
`

export default EditProvider;