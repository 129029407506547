import axios from 'axios';
import { BASE_API_URL } from '../constants/env';

export const getMaterials = async (token, page, size, sortField, filtersData) => {
    
    const url = sortField ? `${BASE_API_URL}/materials/search?page=${page}&size=${size}&sort=${sortField}`
                          : `${BASE_API_URL}/materials/search?page=${page}&size=${size}`;

    return axios.post(url, filtersData, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            const { data } = response;
            return data;
        }).catch(error => {
            console.log('Error on getMaterials call: ', error);
            return error;
        });
}

export const getMaterial = async (token, id) => {
    const url = `${BASE_API_URL}/materials/${id}`;

    return axios.get(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }
    ).then(response => {
        const { data } = response;
        return data;
    }).catch(error => {
        console.log('Error on getMaterial call: ', error);
        return error;
    })
}

export const createMaterial = async (token, data) => {
    const url = `${BASE_API_URL}/materials`;
    const payload = {
        name: data.name,
    };

    return axios.post(url, payload, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then((response) => {
        return response;
    }).catch(error => {
        console.log('Error on createMaterial call: ', error);
        return error.response;
    });
}

export const editMaterial = async (token, materialId, data) => {
    const url = `${BASE_API_URL}/materials/${materialId}`;
    const payload = {
        name: data.name,
    };

    return axios.put(url, payload, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then((response) => {
        return response;
    }).catch(error => {
        console.log('Error on editMaterial call: ', error);
        return error.response;
    });
}

export const deleteMaterial = async (token, materialId) => {
    const url = `${BASE_API_URL}/materials/${materialId}`;

    return axios.delete(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then((response) => {
        return response;
    }).catch(error => {
        console.log('Error on deleteMaterial call: ', error);
        return error.response;
    });
}
