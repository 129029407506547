import axios from 'axios';
import { BASE_API_URL } from '../constants/env';

export const getLocations = async (token, page, size, sortField, filtersData) => {
    
    const url = sortField ? `${BASE_API_URL}/locations/search?page=${page}&size=${size}&sort=${sortField}`
                          : `${BASE_API_URL}/locations/search?page=${page}&size=${size}`;

    return axios.post(url, filtersData, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            const { data } = response;
            return data;
        }).catch(error => {
            console.log('Error on getLocations call: ', error);
            return error;
        });
}

export const getLocation = async (token, id) => {
    const url = `${BASE_API_URL}/locations/${id}`;

    return axios.get(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }
    ).then(response => {
        const { data } = response;
        return data;
    }).catch(error => {
        console.log('Error on getLocation call: ', error);
        return error;
    })
}

export const createLocation = async (token, data) => {
    const url = `${BASE_API_URL}/locations`;
    const payload = {
        name: data.name,
    };

    return axios.post(url, payload, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then((response) => {
        return response;
    }).catch(error => {
        console.log('Error on createLocation call: ', error);
        return error.response;
    });
}

export const editLocation = async (token, locationId, data) => {
    const url = `${BASE_API_URL}/locations/${locationId}`;
    const payload = {
        name: data.name,
    };

    return axios.put(url, payload, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then((response) => {
        return response;
    }).catch(error => {
        console.log('Error on editLocation call: ', error);
        return error.response;
    });
}

export const deleteLocation = async (token, locationId) => {
    const url = `${BASE_API_URL}/locations/${locationId}`;

    return axios.delete(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then((response) => {
        return response;
    }).catch(error => {
        console.log('Error on deleteLocation call: ', error);
        return error.response;
    });
}
