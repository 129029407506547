import { getMinimumReport } from "../services/ReportsService";
import TableContainer from "../components/shared/TableContainer";

const ReportMinimumsValues = () => {
    // ----- Constants
    const columns = [
        {
            name: 'Artículo',
            selector: row => row.articleName,
            sortable: true,
            sortField: 'articleName',
        },
        {
            name: 'Cantidad mínima',
            selector: row => row.minimumAmount,
        },
        {
            name: 'Cantidad',
            selector: row => row.amount,
            sortable: true,
            sortField: 'amount',
            conditionalCellStyles: [
                {
                    when: row => row.amount < row.minimumAmount,
                    style: {
                        color: '#dc3545',
                        fontWeight: 'bold'
                    },
                },
            ],
        }
    ];

    const filters = [
        {
            column: 'articleName',
            input: 'text',
            label: 'Artículo',
            operation: 'like',
            length: 50
        },
    ];

    // ----- Render
    return (
        <div className="container mt-5">
            <h1 className="mb-5">Reporte de Inventario en Riesgo</h1>

            <TableContainer
                columns={columns}
                fetchDataHandler={getMinimumReport}
                updateTable={true}
                filters={filters}
            />
        </div>
    )
}

export default ReportMinimumsValues;