import { getStockReport } from "../services/ReportsService";
import TableContainer from "../components/shared/TableContainer";
import { formatCurrency } from "../utils/numbers";

const ReportStock = () => {
    // ----- Constants
    const columns = [
        {
            name: 'Artículo',
            selector: row => row.articleName,
            sortable: true,
            sortField: 'articleName',
        },
        {
            name: 'Valor unitario',
            selector: row => `₡${formatCurrency(row.averageItemsValue)}`,
            sortable: true,
            sortField: 'averageItemsValue',
        },
        {
            name: 'Valor total',
            selector: row => `₡${formatCurrency(row.totalValue)}`,
            sortable: true,
            sortField: 'totalValue',
        },
        {
            name: 'Cantidad mínima',
            selector: row => row.minimumAmount,
        },
        {
            name: 'Cantidad',
            selector: row => row.amount,
            sortable: true,
            sortField: 'amount',
            conditionalCellStyles: [
                {
                    when: row => row.amount === row.minimumAmount,
                    style: {
                        color: '#ffc107',
                        fontWeight: 'bold'
                    },
                },
                {
                    when: row => row.amount < row.minimumAmount,
                    style: {
                        color: '#dc3545',
                        fontWeight: 'bold'
                    },
                },
                {
                    when: row => row.amount > row.minimumAmount,
                    style: {
                        color: '#198754',
                        fontWeight: 'bold'
                    },
                },
            ],
        },
        {
            name: 'Diferencia',
            selector: row => row.difference,
        },
        {
            name: 'Valor de ajuste',
            selector: row => `₡${formatCurrency(row.differenceValue < 0 ? row.differenceValue * -1 : row.differenceValue)}`,
            sortable: true,
            sortField: 'differenceValue',
            conditionalCellStyles: [
                {
                    when: row => row.difference < 0,
                    style: {
                        color: '#dc3545',
                        fontWeight: 'bold'
                    },
                },
                {
                    when: row => row.difference === 0,
                    style: {
                        color: '#ffc107',
                        fontWeight: 'bold'
                    },
                },
                {
                    when: row => row.difference > 0,
                    style: {
                        color: '#198754',
                        fontWeight: 'bold'
                    },
                },
            ],
        },
    ];

    const filters = [
        {
            column: 'articleName',
            input: 'text',
            label: 'Artículo',
            operation: 'like',
            length: 50
        },
        {
            column: 'tag',
            input: 'tags',
            label: 'Etiquetas',
            operation: 'in',
        },  
    ];

    // ----- Render
    return (
        <div className="container mt-5">
            <h1 className="mb-5">Reporte de Existencias en Tiempo Real</h1>

            <TableContainer
                columns={columns}
                fetchDataHandler={getStockReport}
                updateTable={true}
                filters={filters}
            />
        </div>
    )
}

export default ReportStock;