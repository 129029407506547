import { useCallback, useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"

import { useForm } from "react-hook-form"
import { styled } from "styled-components"

import { getClient, editClient } from "../services/ClientsService"
import { useAppContext } from "../contextApi/context"
import {
    CLIENT_CODE_DUPLICATED,
    CLIENT_ID_DUPLICATED,
    CLIENT_CONTACT_PHONE_DUPLICATED,
    CLIENT_EMAIL_DUPLICATED,
    CLIENT_CONTACT_EMAIL_DUPLICATED,
    CLIENT_PHONE_NUMBER_DUPLICATED
} from "../constants/apiMessages";


const EditClient = () => {
    // ----- Context/Hooks
    const { loggedUser } = useAppContext();
    const { id } = useParams();

    // ----- State
    const [currentClient, setCurrentClient] = useState(null);
    const [showClientEdited, setShowClientEdited] = useState(false);
    const [showErrorDuplicatedData, setShowErrorDuplicatedData] = useState(false);
    const [errorDuplicatedData, setErrorDuplicatedData] = useState('');

    // ----- API Fetch Actions
    const fetchClientData = useCallback(async () => {
        return await getClient(loggedUser.token, id);
    }, [loggedUser.token, id]);

    // ----- Hooks
    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        defaultValues: {
            name: '',
            email: '',
            address: '',
            phoneNumber: '',
            contactName: '',
            code: '',
            identification: '',
            whatsapp: '',
            contactEmail: '',
            contactPhone: '',
            description: '',
        }
    });

    useEffect(() => {
        handleUpdateClient()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // ----- Actions
    const handleUpdateClient = () => {
        fetchClientData().then(data => {
            setCurrentClient(data);
            reset(data);
        });
    }

    const handleEdit = async (data) => {
        clearMessages();
        const response = await editClient(loggedUser.token, currentClient.id, data);

        if (response.status === 200) {
            setShowClientEdited(true);
        } else {
            const { data } = response;
            let errorMessageDuplicated = "El cliente contiene datos que ya se encuentran registrados:";

            if (data.includes(CLIENT_CODE_DUPLICATED)) {
                errorMessageDuplicated += " Código,"
            }

            if (data.includes(CLIENT_ID_DUPLICATED)) {
                errorMessageDuplicated += " Identificación,"
            }

            if (data.includes(CLIENT_EMAIL_DUPLICATED)) {
                errorMessageDuplicated += " Correo electrónico,"
            }

            if (data.includes(CLIENT_PHONE_NUMBER_DUPLICATED)) {
                errorMessageDuplicated += " Número de teléfono,"
            }

            if (data.includes(CLIENT_CONTACT_EMAIL_DUPLICATED)) {
                errorMessageDuplicated += " Correo de contacto,"
            }

            if (data.includes(CLIENT_CONTACT_PHONE_DUPLICATED)) {
                errorMessageDuplicated += " Número de contacto,"
            }

            setErrorDuplicatedData(errorMessageDuplicated.replace(/.$/, "."));
            setShowErrorDuplicatedData(true);
        }
    }

    const clearMessages = () => {
        setShowClientEdited(false);
        setShowErrorDuplicatedData(false);
        setErrorDuplicatedData('');
    }

    // ----- Render
    return (
        <div className="container mt-4">

            <div className="mb-3">
                <Link to="/clientes">Volver a página de clientes</Link>
            </div>
            <h1 className="mt-4">Editar Cliente</h1>

            {showClientEdited &&
                <div className="alert alert-success text-center mb-1" role="alert">
                    Cliente editado satisfactoriamente.
                </div>
            }

            {showErrorDuplicatedData &&
                <div className="alert alert-danger text-center mb-1" role="alert">
                    {errorDuplicatedData}
                </div>
            }

            <FormContainer>
                <form onSubmit={handleSubmit(handleEdit)}>
                    <label className="d-flex mb-1" htmlFor="code">Código</label>
                    <input className="form-control" type="text" id="code" autoComplete="off" {...register("code", { required: true, maxLength: 20 })} maxLength={20} autoFocus />
                    <span className="my-1" role="alert">
                        {errors.code?.type === "required" && `Campo requerido`}
                        {errors.code?.type === "maxLength" && `Formato inválido`}
                    </span>

                    <label className="d-flex mb-1" htmlFor="name">Nombre</label>
                    <input className="form-control" type="text" id="name" autoComplete="off" {...register("name", { required: true, maxLength: 50 })} maxLength={50} />
                    <span className="my-1" role="alert">
                        {errors.name?.type === "required" && `Campo requerido`}
                        {errors.name?.type === "maxLength" && `Formato inválido`}
                    </span>

                    <label className="d-flex mb-1" htmlFor="identification">Identificación</label>
                    <input className="form-control" type="text" id="identification" autoComplete="off" {...register("identification", { required: true, maxLength: 20 })} maxLength={20} />
                    <span className="my-1" role="alert">
                        {errors.identification?.type === "required" && `Campo requerido`}
                        {errors.identification?.type === "maxLength" && `Formato inválido`}
                    </span>

                    <label className="d-flex mb-1" htmlFor="email">Correo electrónico</label>
                    <input className="form-control" type="text" id="email" autoComplete="off" {...register("email", { required: true, maxLength: 50, pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/ })} maxLength={50} />
                    <span className="my-1" role="alert">
                        {errors.email?.type === "required" && `Campo requerido`}
                        {errors.email?.type === "maxLength" && `Formato inválido`}
                        {errors.email?.type === "pattern" && `Formato de correo incorrecto`}
                    </span>

                    <label className="d-flex mb-1" htmlFor="address">Dirección</label>
                    <input className="form-control" type="text" id="address" autoComplete="off" {...register("address", { maxLength: 100 })} maxLength={100} />
                    <span className="my-1" role="alert">
                        {errors.address?.type === "maxLength" && `Formato inválido`}
                    </span>

                    <label className="d-flex mb-1" htmlFor="phoneNumber">Número de teléfono</label>
                    <input className="form-control" type="text" id="phoneNumber" autoComplete="off" {...register("phoneNumber", { required: true, maxLength: 20 })} maxLength={20} />
                    <span className="my-1" role="alert">
                        {errors.phoneNumber?.type === "required" && `Campo requerido`}
                        {errors.phoneNumber?.type === "maxLength" && `Formato inválido`}
                    </span>

                    <label className="d-flex mb-1" htmlFor="contactName">Nombre de contacto</label>
                    <input className="form-control" type="text" id="contactName" autoComplete="off" {...register("contactName", { required: true, maxLength: 50 })} maxLength={50} />
                    <span className="my-1" role="alert">
                        {errors.contactName?.type === "required" && `Campo requerido`}
                        {errors.contactName?.type === "maxLength" && `Formato inválido`}
                    </span>

                    <label className="d-flex mb-1" htmlFor="whatsapp">Número de celular</label>
                    <input className="form-control" type="text" id="whatsapp" autoComplete="off" {...register("whatsapp", { maxLength: 20 })} maxLength={20} />
                    <span className="my-1" role="alert">
                        {errors.whatsapp?.type === "maxLength" && `Formato inválido`}
                    </span>

                    <label className="d-flex mb-1" htmlFor="contactEmail">Correo de contacto</label>
                    <input className="form-control" type="text" id="contactEmail" autoComplete="off" {...register("contactEmail", { required: true, maxLength: 50, pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/ })} maxLength={50} />
                    <span className="my-1" role="alert">
                        {errors.contactEmail?.type === "required" && `Campo requerido`}
                        {errors.contactEmail?.type === "maxLength" && `Formato inválido`}
                        {errors.contactEmail?.type === "pattern" && `Formato de correo incorrecto`}
                    </span>

                    <label className="d-flex mb-1" htmlFor="contactPhone">Número de contacto</label>
                    <input className="form-control" type="text" id="contactPhone" autoComplete="off" {...register("contactPhone", { required: true, maxLength: 20 })} maxLength={20} />
                    <span className="my-1" role="alert">
                        {errors.contactPhone?.type === "required" && `Campo requerido`}
                        {errors.contactPhone?.type === "maxLength" && `Formato inválido`}
                    </span>

                    <label className="d-flex mb-1" htmlFor="description">Descripción</label>
                    <textarea className="form-control" id="description" rows="3" {...register("description", { maxLength: 100 })} maxLength={100} />
                    <span className="my-1" role="alert">
                        {errors.description?.type === "maxLength" && `Formato inválido`}
                    </span>
                    <div className="d-flex justify-content-center">
                        <button className="btn btn-danger mt-3" type="submit">Guardar cambios</button>
                    </div>
                </form>
            </FormContainer>
        </div>
    )
}

const FormContainer = styled.div`
    margin: 25px auto 0;
    padding: 40px 40px 20px;
    width: 70%;

    form {
        display: grid;
    
        > label {
            font-size: 15px;
            font-weight: bold;
        }

        > span {
            color: #D04437;
            font-size: 13px;
            min-height: 12px;
        }

        button {
            background-color: #D04437;
            width: 30%;
        }
    }
`;

export default EditClient;