import { useCallback, useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"

import { useForm } from "react-hook-form"
import { styled } from "styled-components"

import { getLocation, editLocation } from "../services/LocationsService"
import { useAppContext } from "../contextApi/context"
import { LOCATION_NAME_DUPLICATED } from "../constants/apiMessages"


const EditLocation = () => {
    // ----- Context/Hooks
    const { loggedUser } = useAppContext();
    const { id } = useParams();

    // ----- State
    const [currentLocation, setCurrentLocation] = useState(null);
    const [showLocationEdited, setShowLocationEdited] = useState(false);
    const [showErrorDuplicatedData, setShowErrorDuplicatedData] = useState(false);
    const [errorDuplicatedData, setErrorDuplicatedData] = useState("");

    // ----- API Fetch Actions
    const fetchLocationData = useCallback(async () => {
        return await getLocation(loggedUser.token, id);
    }, [loggedUser.token, id]);

    // ----- Hooks
    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        defaultValues: {
            name: '',
        }
    });

    useEffect(() => {
        handleUpdateLocation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    // ----- Actions
    const handleUpdateLocation = () => {
        fetchLocationData().then(data => {
            setCurrentLocation(data);
            reset(data);
        });
    }

    const handleEdit = async (data) => {
        clearMessages();
        const response = await editLocation(loggedUser.token, currentLocation.id, data);

        if (response.status === 200) {
            setShowLocationEdited(true);
        } else {
            // Error from API
            const { data } = response;
            let errorMessageDuplicated = "La ubicación contiene datos que ya se encuentran registrados:";

            if (data.includes(LOCATION_NAME_DUPLICATED)) {
                errorMessageDuplicated += " Nombre,"
            }

            setErrorDuplicatedData(errorMessageDuplicated.replace(/.$/, "."));
            setShowErrorDuplicatedData(true);
        }
    }

    const clearMessages = () => {
        setShowLocationEdited(false);
        setShowErrorDuplicatedData(false);
    }

    // ----- Render
    return (
        <div className="container mt-4">
            <div className="mb-3">
                <Link to="/ubicaciones">Volver a página de ubicaciones</Link>
            </div>
            <h1 className="mt-4">Editar Ubicación</h1>

            {showLocationEdited &&
                <div className="alert alert-success text-center mb-1" role="alert">
                    Ubicación editada satisfactoriamente.
                </div>
            }

            {showErrorDuplicatedData &&
                <div className="alert alert-danger text-center mb-1" role="alert">
                    {errorDuplicatedData}
                </div>
            }


            <FormContainer>
                <form onSubmit={handleSubmit(handleEdit)}>
                    <label className="d-flex mb-1" htmlFor="name">Nombre</label>
                    <input className="form-control" type="text" id="name" autoComplete="off" maxLength={50} {...register("name", { required: true, maxLength: 50 })} autoFocus />
                    <span className="my-1" role="alert">
                        {errors.name?.type === "required" && `Campo requerido`}
                        {errors.name?.type === "maxLength" && `Formato inválido`}
                    </span>

                    <div className="d-flex justify-content-center">
                        <button className="btn btn-danger mt-3" type="submit">Guardar cambios</button>
                    </div>
                </form>
            </FormContainer>
        </div>
    )
}

const FormContainer = styled.div`
    margin: 25px auto 0;
    padding: 40px 40px 20px;
    width: 70%;

    form {
        display: grid;

        > label {
            font-size: 15px;
            font-weight: bold;
        }

        > span {
            color: #D04437;
            font-size: 13px;
            min-height: 12px;
        }

        button {
            background-color: #D04437;
            width: 30%;
        }
    }
`;

export default EditLocation;